import { isContactPhone } from '@/utils/validate'

export const column = {
  accountName: {
    label: '供应商名称',
    prop: 'supplierName',
    rules: [
      {
        required: true
      }
    ]
  },
  contactName: {
    label: '联系人',
    prop: 'contactName',
    rules: [
      {
        required: true
      }
    ]
  },
  phone: {
    label: '联系方式',
    prop: 'phone',
    rules: [
      {
        required: true
      },
      {
        validator: isContactPhone
      }
    ]
  },
  address: {
    label: '地址',
    prop: 'address',
    rules: [
      {
        required: true
      }
    ]
  },
  businessLicense: {
    label: '营业执照',
    prop: 'businessLicense',
    rules: [
      {
        required: true
      }
    ]
  }
}
export const addFormOption = {
  labelWidth: 130,
  size: 'small',
  column: [
    column.accountName,
    column.contactName,
    column.phone,
    column.address,
    column.businessLicense
  ]
}

export const editFormOption = {
  labelWidth: 130,
  size: 'small',
  column: [
    column.accountName,
    column.contactName,
    column.phone,
    column.address,
    column.businessLicense
  ]
}
