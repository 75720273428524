<template>
  <dialogForm
    v-model="form"
    @submit="onsubmit"
    @close="onclose"
    v-bind="option.dialogAttrs"
    v-on="$listeners"
  >
    <template #businessLicense="{ column: { prop } }">
      <ImgUpload
        :validateEvent="true"
        :limit="1"
        :files.sync="form[prop]"
      />
    </template>
  </dialogForm>
</template>

<script>
import ImgUpload from '@/components/fileUpload/imgUpload'
import { validData } from '@/components/avue/utils/util'
import { isFile } from '@/utils'
import { addFormOption, editFormOption } from './const'
import { createSupplier, supplierUpdate } from '@/api/order/supplier'

const DEFAULT_OPTION = {
  width: 600
}

async function uploadBusinessLicense(file) {
  if (!isFile(file)) return [false, '']
  const res = await $uploadOSSPics([
    {
      files: [file],
      prop: 'path',
      dirPrefix: $ossDirMapWithType.businessLicense
    }
  ])
  return [!res, res?.path]
}

export default {
  components: {
    ImgUpload
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    type: {
      type: String,
      default: 'add'
    }
  },

  data() {
    return {
      form: {
        businessLicense: []
      }
    }
  },

  computed: {
    option({ type }) {
      const defaultOption = {
        getParams: async () => {
          const { businessLicense, ...params } = this.form
          const [err, path] = await uploadBusinessLicense(businessLicense[0])
          if (err) return [true, null]
          if (path) params.businessLicense = path
          return [false, params]
        }
      }

      const list = {
        add: {
          dialogAttrs: {
            ...DEFAULT_OPTION,
            title: '添加供应商',
            btnType: 'primary',
            option: addFormOption
          },
          getParams: defaultOption.getParams,
          submitApi: createSupplier
        },

        edit: {
          dialogAttrs: {
            ...DEFAULT_OPTION,
            title: '编辑供应商',
            btnText: '编辑',
            btnType: 'text',
            option: editFormOption,
            beforeOpen: () => {
              const { column } = editFormOption
              this.form = column.reduce((cur, prev) => {
                cur[prev.prop] = $GET(this.data, prev.prop, '')
                return cur
              }, {})
              const businessLicense = $GET(this.data, 'businessLicense', '')
              this.form.businessLicense = businessLicense ? [{ url: businessLicense }] : []
              this.form.id = this.data.id
              return true
            }
          },
          getParams: defaultOption.getParams,
          submitApi: supplierUpdate
        }
      }
      return validData(list[type], list.add)
    }
  },

  methods: {
    async onsubmit(form, done) {
      const [err, params] = await this.option.getParams()
      if (err) {
        done(false)
        return
      }
      const res = await awaitResolveDetail(this.option.submitApi(params))
      done(!!res)
    },

    onclose() {
      Object.assign(this, this.$options.data())
    }
  }
}
</script>
<style lang="scss" scoped>
.tip {
  position: relative;
  top: -15px;
}
</style>
